import { render, staticRenderFns } from "./List1.vue?vue&type=template&id=f264337e&scoped=true&a=5"
import script from "./List1.vue?vue&type=script&lang=js&a=5"
export * from "./List1.vue?vue&type=script&lang=js&a=5"
import style0 from "./List1.vue?vue&type=style&index=0&id=f264337e&scoped=true&lang=scss&a=5"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f264337e",
  null
  
)

export default component.exports